<template>
    <div class="coianter " style="background-color:white;">
        <div class="idteail">
            <div style="padding-left:10px;">
            <el-tabs v-model="activeName" @tab-click="handleClicktab">
                <el-tab-pane label="立项信息" name="first"></el-tab-pane>
                <el-tab-pane label="初稿审查" name="second"></el-tab-pane>
                <el-tab-pane label="征求意见稿" name="third"></el-tab-pane>
                <el-tab-pane label="送审稿审查" name="fourth"></el-tab-pane>
                <el-tab-pane label="发布备案" name="five"></el-tab-pane>
            </el-tabs>
            </div>
            <div class=" " style="float:right">
              <i class="el-icon-close " @click="$router.go(-1)" >
              </i>
            </div>
        </div>
        <div v-if="activeName == 'first'">
          <div class="flexstyle title ">
              <div class="centerstyle">
              <div class="titleicon"></div>
              <div class="titletext">基本信息</div>  
              </div>
          </div>
          <!-- <div style="width:100%;height:5px;"></div> -->
          <div class=""  style="margin:0 auto;width:100%;background:white;">
              <div style="margin: 20px;margin-top:1px; padding-top:30px;">
              <table rules='all' >
                  <tr  class="tableTitle-tr">
                    <td class="tdtitle">标准名称</td>
                    <td class="tdcontent">{{form.proName}}</td>
                    <td rowspan="2" class="tdtitle">类型</td>
                    <td>
                      <span v-if="form.biaozhunType==1">标准</span>
                      <span v-if="form.biaozhunType==2">标准设计</span>
                      <span v-if="form.biaozhunType==3">推荐性技术规程</span>
                    </td>
                    <td rowspan="2" class="tdtitle"> 项目管理员</td>
                    <td rowspan="2" style="width:100px;"> </td>
                  </tr>
                  <tr class="tableTitle-tr">
                    <td class="tdtitle">英文名称</td>
                    <td >{{form.USname}}</td>
                    <td >
                      <span v-if="form.zhidingType==1">制定</span>
                      <span v-if="form.zhidingType==2">修订</span>
                    </td>
                  </tr>
                  <tr  class="tableTitle-tr">
                  <td class="tdtitle">第一主编单位：</td>
                  <td>{{form.firstUnit}}</td>
                  <td class="tdtitle">地址(邮编)：</td>
                  <td colspan="3" style="width:100px;">{{form.firstAddress}}</td>
                  </tr>
                  <tr class="tableTitle-tr">
                  <td class="tdtitle">单位负责人：</td>
                  <td>{{form.firstHandler}}</td>
                  <td class="tdtitle">联系电话：</td>
                  <td class="tdcontent2">{{form.firstTel}}</td>
                  <td class="tdtitle">电子邮箱：</td>
                  <td class="tdcontent2">{{form.firstEmail}}</td>
                  </tr>
                  <tr class="tableTitle-tr">
                  <td class="tdtitle">项目负责人：</td>
                  <td>{{form.firstProHandler}}</td>
                  <td class="tdtitle">联系电话：</td>
                  <td>{{form.firstProHandlerTel}}</td>
                  <td class="tdtitle">电子邮箱：</td>
                  <td>{{form.firstProHandlerEmail}}</td>
                  </tr>
                  <tr class="tableTitle-tr">
                  <td class="tdtitle">项目联系人：</td>
                  <td>{{form.firstProContact}}</td>
                  <td class="tdtitle">联系电话：</td>
                  <td>{{form.firstProContactTel}}</td>
                  <td class="tdtitle">电子邮箱：</td>
                  <td>{{form.firstProContactEmail}}</td>
                  </tr>
                  <tr class="tableTitle-tr">
                  <td class="tdtitle">第二主编单位：</td>
                  <td>{{form.secUnit}}</td>
                  <td class="tdtitle">地址(邮编)：</td>
                  <td colspan="3" style="width:100px;">{{form.secHandleEmail}}</td>
                  </tr>
                  <tr class="tableTitle-tr">
                  <td class="tdtitle">单位负责人：</td>
                  <td>{{form.secHandler}}</td>
                  <td class="tdtitle">联系电话：</td>
                  <td>{{form.secTel}}</td>
                  <td class="tdtitle">电子邮箱：</td>
                  <td>{{form.secEmail}}</td>
                  </tr>
                  <tr class="tableTitle-tr">
                  <td class="tdtitle">项目负责人：</td>
                  <td>{{form.secProHandler}}</td>
                  <td class="tdtitle">联系电话：</td>
                  <td>{{form.secProTel}}</td>
                  <td class="tdtitle">电子邮箱：</td>
                  <td>{{form.secProEmail}}</td>
                  </tr>
                  <!-- <tr class="tableTitle-tr">
                  <td class="tdtitle">项目联系人：</td>
                  <td>{{form.USname}}</td>
                  <td class="tdtitle">联系电话：</td>
                  <td>{{form.USname}}</td>
                  <td class="tdtitle">电子邮箱：</td>
                  <td>{{form.USname}}</td>
                  </tr> -->
                  <tr class="tableTitle-tr">
                  <td class="tdtitle">参编单位：</td>
                  <td colspan="5">{{form.join_companies}}</td>
                  </tr>
                  <tr class="tableTitle-tr">
                  <td class="tdtitle">主要起草人员</td>
                    <td colspan="5">
                        <el-table :data="tableData" border style="width: 100%"> 
                        <el-table-column prop="parter" label="姓名"> </el-table-column>
                        <el-table-column prop="career" label="所学专业"> </el-table-column>
                        <el-table-column prop="careerNow" label="现从事专业"> </el-table-column>
                        <el-table-column prop="job" label="职称/职务"> </el-table-column>
                        <el-table-column prop="work" label="工作单位"> </el-table-column>
                        <el-table-column prop="take" label="承担工作"> </el-table-column>
                        </el-table>
                    </td>
                  </tr>
              </table>
                <!-- <div class="" style="float:right">  主要起草人员分页
                  <div>
                    <el-pagination 
                      @size-change="sizeChange" 
                      @current-change="currentChange"
                      :current-page="page" 
                      :page-size="size" 
                      layout="prev, pager, next" 
                      :total="total">
                    </el-pagination>
                  </div>
                    
                </div> -->
              </div>
          </div>
          <div class="flexstyle title ">
              <div class="centerstyle">
              <div class="titleicon"></div>
              <div class="titletext">编制大纲</div>  
              </div>
          </div>
          <!-- <div style="width:100%;height:5px;"></div> -->
          <div class="" style="margin:0 auto;width:100%;background:white;">
              <div style="margin: 20px;margin-top:1px; padding-top:30px;">
              <table rules='all' >
                  <tr  class="tableTitle-tr">
                      <td class="tdtitle">一、 目的、意义</td>
                      <td  class="bianzhitd" colspan="5" style="padding-left:30px;">
                      {{form.purpose}}
                  </td>
                              </tr>
                  <tr  class="tableTitle-tr">
                  <td class="tdtitle"> 二、 适用范围和主要 技术内容</td>
                      <td  colspan="5" style="padding-left:30px;">
                      {{form.content}}
                  </td>
                              </tr>
                  <tr  class="tableTitle-tr">
                    <td class="tdtitle">三、 现有工作基础和需解决的重点问题,必要的测试验证 项目</td>
                    <td colspan="5" style="padding-left:30px;">
                    {{form.problem}}
                  </td>
                              </tr>
                  <tr  class="tableTitle-tr">
                    <td class="tdtitle">四、 与有关法律法规、 相关标准的关系</td>
                    <td colspan="5" style="padding-left:30px;">
                      {{form.law}}
                  </td>
                              </tr>
                  <tr class="tableTitle-tr">
                  <td class="tdtitle">五、经费组成</td>
                  <!-- <td colspan="2" style="height:140px; padding-left:30px;"></td> -->
                  <td>拨款：{{form.bokuan}}万元</td>
                  <td>自筹：{{form.zichou}}万元</td>
                  <td></td>
                  </tr>
                  <tr class="tableTitle-tr">
                  <td class="tdtitle">六、 社会经济效益分析</td>
                  <td colspan="5" style=" padding-left:30px;">
                      {{form.xiaoyi}}
                  </td>
                  </tr>
                  <tr class="tableTitle-tr2" >
                  <td class="tdtitle" rowspan="6">进度计划</td>
                  <td class="shijianstyle" > 起始时间 </td>
                  <td class="shijianstyle">  结束时间 </td>
                  <td class="shijianstyle"> 工作内容 </td>
                  </tr>
                  <tr class="tableTitle-tr2" >
                  <td class="shijianstyle" >{{ momentBytime(form.start_time1)}}</td>
                  <td class="shijianstyle" >{{ momentBytime(form.endtime1)}}</td>
                  <td class="shijianstyle">完成编制大纲及技术条件分析</td>
                  </tr>
                  <tr class="tableTitle-tr2" >
                  <td class="shijianstyle" >{{ momentBytime(form.start_time2)}}</td>
                  <td class="shijianstyle" >{{ momentBytime(form.endtime2)}}</td>
                  <td class="shijianstyle">完成初稿</td>
                  </tr>
                  <tr class="tableTitle-tr2" >
                  <td  class="shijianstyle">{{ momentBytime(form.start_time3)}}</td>
                  <td class="shijianstyle" >{{ momentBytime(form.endtime3)}}</td>
                  <td class="shijianstyle">完成征求意见稿</td>
                  </tr>
                  <tr class="tableTitle-tr2" >
                  <td class="shijianstyle" >{{ momentBytime(form.start_time4)}}</td>
                  <td  class="shijianstyle">{{ momentBytime(form.endtime4)}}</td>
                  <td class="shijianstyle">完成送审稿</td>
                  </tr>
                  <tr class="tableTitle-tr2" >
                  <td class="shijianstyle" >{{ momentBytime(form.start_time5)}}</td>
                  <td class="shijianstyle" >{{ momentBytime(form.endtime5)}}</td>
                  <td class="shijianstyle">完成报批稿</td>
                  </tr>
              </table>
              </div>
          </div>
          <div class="flexstyle title " >
              <div class="centerstyle">
              <div class="titleicon"></div>
              <div class="titletext">审查意见</div>  
              </div>
          </div>
          <div class="" style="margin:0 auto;height:230px;width:100%;background:white;">
              <div class="tiwen" style="margin-left:30px;width:97%;background:white;">
                  <textarea disabled  class="tiwenText" v-model="form.shencha" style="font-size:16px" ></textarea>
              </div>
          </div>
          <div class="comit">
              <div class="baocun">
                <router-link :to="'/projectforcom/updateProject?id='+id"> 
                  <el-button type="primary">修改</el-button>
                </router-link>
              </div>
              <!-- <div class="baocun">
                  <el-button type="info" @click="saveProject()">退回</el-button>
              </div> -->
              <div class="baocun">
                  <el-button type="warning" @click="gotoStep()">下载申请表</el-button>
              </div>
          </div>
        </div>
        <div v-if="activeName == 'second'" style="background-color:white;">
          <div class="flexstyle title ">
              <div class="centerstyle">
                <div class="titleicon"></div>
                <div class="titletext">相关文件</div>  
              </div>
          </div>
          <div class="flexstyle " >
            <div class="flexstyle " style="margin-right:30px; width:350px;height:60px;background: #EDF3FF;border: 1px solid #B2CCFA;">
              <div style="width: 3px; height: 60px; background: #3082E2;"></div>
              <div style="margin-left:10px;margin-top:5px;font-size: 16px;color: #666666;">
                <div>工程建设标准初稿.pdf</div>
                <div style="margin-top:8px;color:#3082E2">点击此处上传<i class="el-icon-upload2"></i></div>
              </div>
            </div>
            <div class="flexstyle " style="width:350px;height:60px;background: #EDF3FF;border: 1px solid #B2CCFA;">
              <div style="width: 3px; height: 60px; background: #3082E2;"></div>
              <div style="margin-left:10px;margin-top:5px;font-size: 16px;color: #666666;">
                <div>工程建设标准初稿.pdf</div>
                <div style="margin-top:8px;color:#3082E2">点击此处上传<i class="el-icon-upload2"></i></div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
// projectTableInfo,
import {getBiaozhunDetail  } from "../../../../api/project";
import JiangSu from "../../../../assets/jiangsu.json";
import moment from 'moment';
export default {
    data() {
        return {
            labelPosition: "right",
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,
            fileList: [],
            form: {
                stars: 1,
            },
            cityOptions: JiangSu.city,
            countyOptions: [],
            value1:'',
            value2:'',
            value3:'',
            activeName: 'first',
            tableData:[],
            tableData2: [{
              start_time: '',
              endtime: '',
              worktext: '',
            }, {
              start_time: '',
              endtime: '',
              worktext2: '',
            }, {
              start_time: '',
              endtime: '',
              worktext3: '',
            }, {
              start_time: '',
              endtime: '',
              worktext4: '',
            }, {
              start_time: '',
              endtime: '',
              worktext5: '',
            }],
            textName:'',
            id:'',
            token:'',
            page: 1, //第几页
            size: 3, //一页多少条
            total: 0, //总条目数
        };
    },
    mounted() {
      this.getIdAndToken();
    },
    methods: {
        momentBytime(date){
            let y= moment(date).format('YYYY-MM-DD');
            return y;
        },
      getIdAndToken(){
        this.id = this.$route.query.id;
        this.token = this.$store.state.token//获取token
        this.getInfo();
      },
      //获取详情
      getInfo() {
        getBiaozhunDetail({id:this.id,token:this.token}).then((res) => {
          console.log('详情',res)
          this.form = res.data
          //allData为全部数据
          this.tableData = res.data.fu  //主要起草人员展开无分页
          // this.tableData = res.data.fu.slice(主要起草人员分页  
          //   (this.page - 1) * this.size,
          //   this.page * this.size
          // );
          // this.total=res.data.fu.length
          console.log('this.total',this.total)
          this.tableData2[0].start_time = res.data.start_time1
          this.tableData2[0].endtime = res.data.endtime1
          this.tableData2[1].start_time = res.data.start_time2
          this.tableData2[1].endtime = res.data.endtime2
          this.tableData2[2].start_time = res.data.start_time3
          this.tableData2[2].endtime = res.data.endtime3
          this.tableData2[3].start_time = res.data.start_time4
          this.tableData2[3].endtime = res.data.endtime4
          this.tableData2[4].start_time = res.data.start_time5
          this.tableData2[4].endtime = res.data.endtime5
          
        }).catch((err) => {
          console.log(err)
          });
      },
      gotoStep(){
      // let url = '/formal/proDetailDownLoad?id='+id;
      this.$router.push({
          path: '../../formal/proDetailDownLoad?id='+this.id
        })
      // return url;
      },
      //page改变时的回调函数，参数为当前页码
      currentChange(val) {
        console.log("翻页，当前为第几页", val);
        this.page = val;
        this.getInfo();
      },
      //size改变时回调的函数，参数为当前的size
      sizeChange(val) {
        console.log("改变每页多少条，当前一页多少条数据", val);
        this.size = val;
        this.page = 1; 
        this.getInfo();
      },
      //切换导航
      handleClicktab(tab, event) {
        console.log(tab, event);
      },
      //图片上传成功
      photo_success(re, file, fileList) {
      console.log(fileList);
      },
      fileUploadSuccess(response, file, fileList) {
      console.log(fileList);
      let tmpList = fileList.slice(fileList.length - 1);
      this.fileList = tmpList.map((item) => {
          return item.response.data;
      });
      console.log(this.fileList);
      },
      //切换市
      cityChange(city) {
      for (let i = 0; i < this.cityOptions.length; i++) {
          if (this.cityOptions[i].name == city) {
          this.countyOptions = this.cityOptions[i].area;
          break;
          }
      }
      },

      //保存项目
      saveProject() {
          if(this.value1 == '' ){
              console.log("this.value1",this.value1)
          }
      },
      handleRemove(file) {
      console.log(file, "2");
      },
      handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      },
      handleDownload(file) {
      console.log(file, "1");
      },

      // 分页自带的函数，当pageSize变化时会触发此函数
      // handleSizeChange(val) {
      //     console.log('11',val)
      //     // this.pageSize = val;
      //     // this.getPageData();
      // },
      // // 分页自带函数，当currentPage(页数)  变化时会触发此函数
      // async handleCurrentChange(val) {
      //     this.currentPage = val;
      //     // this.getPageData();
      // },
    },
};
</script>
<style scoped>
/deep/ .el-input {
  width: 250px;
}
</style>
<style lang="scss" scoped>
.coianter {
  width: 100%;
  background-color: #f4f4f4f4;
  height: 100%;
}

.idteail {
  margin: 0 0 0px 0px;
  width: 100%;
  // height:60px;
  background-color: #ffffff;
  // box-shadow: #cccbcb 0px -1px 10px;
  .idtname {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-left: 63px;
  }

  .back {
    margin-right: 23px;
    font-size: 20px;
  }
}

.title{
  background: white;
  width:100%;
  .titleicon{
    margin:10px;
    width: 4px;
    height: 24px;
    background: #3082E2;
  }
  .titletext{
    font-size: 22px;
    font-family: Adobe Heiti Std;
    color: #3082E2;
    line-height: 65px;
  }
}
.comit {
  padding: 20px 0;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: white;
  .baocun {
    margin: 0 25px;
  }
}
.name_input {
  width: 100%;
}
.name_input > .el-form-item {
  width: 100%;
}
.name_input > .el-form-item .el-input {
  width: 100%;
}
.borderstyle {
    border: 1px solid;
}
.centerstyle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.flexstyle {
    display: flex;
    flex-direction: row;
}
.tableTitle-tr{
  height:60px;
  font-size: 16px;
  .tdtitle{
    width:150px;
    border: 1px solid #3082E2;
    background: #EAF2FC;
    text-align:center
  }
 
  .tdcontent{
    width: 500px;
  }
  .tdcontent2{
    width: 300px;
  }
  .bianzhitd{
  width: 1415px;
}
}
.tableTitle-tr2{
  font-size: 16px;
  .tdtitle{
    width:150px;
    border: 1px solid #3082E2;
    background: #EAF2FC;
    text-align:center
  }
   .shijianstyle{
 text-align:center
  }
  .tdcontent{
    width: 500px;
  }
  .tdcontent2{
    width: 300px;
  }
  .bianzhitd{
width: 1415px;
}
}
.tiwen{
    height:160px;
    box-shadow: 0px 6px 11px 2px rgba(72, 77, 84, 0.2);
    .tiwenText{
        width:100%;
        height:100%;
        background:rgba(93, 160, 236, 0.1);
        border: 0px;
        resize:none
    }
}

table{
  border:0;
border-collapse:collapse;

}
td{border:1px solid #3082E2;}//这里改
</style>
